import React, { useState } from "react";
import {
  KeyValue,
  PaginationCardTableSwitch,
  SidePane,
  StatelessTab,
} from "retro/index";
import OpenIcon from "@atlaskit/icon/glyph/open";
import Moment from "moment";
import ReactJson from "react-json-view";

export default function UtilityLogs(data = []) {
  const [tab, setTab] = useState("Utility Data");
  const [openSidePane, setOpenSidePane] = useState(undefined);
  return (
    <div>
      <PaginationCardTableSwitch
        mode={"list"}
        width={175}
        headers={[
          "Updated At",
          "Description",
          "Owner Name",
          "Owner Email",
          "Utility Id",
          "Status",
          "Actions",
        ].map((title) => ({
          weight: ["Date"].includes(title) ? 1.5 : 1,
          title,
          class: title === "Date" ? "table-sticky-row-first" : "",
        }))}
        // loading={loading}
        rows={data?.data?.map((row) => {
          const click = () => {
            setOpenSidePane(row);
          };
          return [
            {
              weight: 2,
              class: "table-sticky-row-first",
              children: (
                <div className="flex flex-row">
                  <p
                    className="pointer text-underline fw-500"
                    onClick={() => {
                      setOpenSidePane(row);
                    }}
                  >
                    {row?.updatedAt
                      ? Moment(row?.updatedAt).format("DD-MM-YYYY HH:mm a")
                      : "-"}
                  </p>
                </div>
              ),
            },

            {
              weight: 1,
              children: (
                <>
                  <p className="text-truncate">{row?.metadata?.[0] || "-"}</p>
                </>
              ),
            },
            {
              weight: 1,
              children: (
                <>
                  <p className="text-truncate">
                    {row?.owner?.ownerName || "-"}
                  </p>
                </>
              ),
            },
            {
              weight: 1,
              children: (
                <>
                  <p className="text-truncate">
                    {row?.owner?.ownerEmail || "-"}
                  </p>
                </>
              ),
            },
            {
              weight: 1,
              children: (
                <>
                  <p className="text-truncate">{row?.utilityId || "-"}</p>
                </>
              ),
            },
            {
              weight: 1,
              children: (
                <>
                  <p className="text-truncate">{row?.status || "-"}</p>
                </>
              ),
            },
            {
              buttons: [
                {
                  title: "View Details",
                  className: "btn-secondary btn-sm",
                  before: <OpenIcon size="small" />,
                  onClick: click,
                },
              ],
              actions: [
                {
                  title: "Quick Actions",
                  actions: [
                    {
                      title: "View Details",
                      before: <OpenIcon size="small" />,
                      onClick: click,
                    },
                  ],
                },
              ],
            },
          ];
        })}
        data={data?.data}
      />
      {openSidePane && (
        <SidePane
          title="Utillity Logs"
          tabs={
            <StatelessTab
              onClick={setTab}
              style={{ marginBottom: "calc(-1rem - 1px)" }}
              tabs={["Utility Data", "Meta Data"]}
              active={tab}
            />
          }
          description={openSidePane?.metadata?.[0] || "0"}
          onClose={() => {
            setOpenSidePane(undefined);
            setTab("Utility Data");
          }}
        >
          {tab === "Utility Data" && (
            <>
              <div className="mt2">
                <ReactJson src={openSidePane?.request} />
              </div>
            </>
          )}
          {tab === "Meta Data" && (
            <>
              <div className="mt2">
                <KeyValue
                  title="Owner Id"
                  value={openSidePane?.owner?.ownerId || "-"}
                />
                <KeyValue
                  title="Owner Name"
                  value={openSidePane?.owner?.ownerName || "-"}
                />
                <KeyValue
                  title="Owner Email"
                  value={openSidePane?.owner?.ownerEmail || "-"}
                />
                <h3>Details</h3>
                <ul>
                  {openSidePane?.metadata?.map((meta) => {
                    return <li>{meta}</li>;
                  })}
                </ul>
              </div>
            </>
          )}
        </SidePane>
      )}
    </div>
  );
}
