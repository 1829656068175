/**
 * Author[Lakshay Jain]
 * Version 1.0.1
 * 7th December | 1.0.1 | Shubham Rawat | removed add the menu options except transactions for yesbank
*/
import React from 'react'
import QueuesIcon from "@atlaskit/icon/glyph/queues";
import {ExpandableSidebar, NotFound, Wrapper} from "../../../../retro";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import TrainQueue from './TrainQueue'
import VisaQueue from './VisaQueue'
import Transaction from './Transaction'
import TransactionDetails from './TransactionDetails'
import BookIcon from "@atlaskit/icon/glyph/book";
import FileIcon from "@atlaskit/icon/glyph/file";
import Flight from './Flight'
import Train from './Train'
import Hotel from './Hotel'
import Da from './Da'
import Pg from './Pg'
import All from './All'
import Utility from './Utility'
import HotelQueue from './HotelQueue'
import Bus from './Bus'
import Insurance from './Insurance'
import Visa from './Visa'
import useFunctionallityHook from "../../../Hooks/useFunctionallityHook";
import useConfigHook from 'App/Hooks/useConfigHook';
import Import  from "./Import"
import ROLES from 'App/Constants/Roles';
import useRoleHook from 'App/Hooks/useRoleHook';

export default function index() {
	const History = useHistory();
	const CONFIG = useConfigHook();
	let ORIGIN = CONFIG.origin.code;
	const location = useLocation();
	const LOCAL_DA = useFunctionallityHook().localDa;
	const OFFLINE = useFunctionallityHook().offlineQueues;
	const IsSupport = useRoleHook(ROLES.SUPPORT);
	const isReports = useRoleHook(ROLES.REPORTS);
	return (
		<div className="flex h-100h w-100">
			{
				ORIGIN.includes('YESUAT') || ORIGIN.includes('YES-PROD') || ORIGIN.includes('MMT')
				?
				<ExpandableSidebar items={[
					{
						title:"Actions",
						actions:[
							{
								title: "Transactions",
								icon : (<FileIcon size="small"/>),
								active:location.pathname==='/app/apps/meta/transaction',
								onClick:()=>{
									History.push('/app/apps/meta/transaction')
								},
							},
						]
					}
				]} />
				: (
			<ExpandableSidebar items={ [
				{
					title:"Actions",
					actions:[
						{
							title: "Flight Bookings",
							icon : (<BookIcon size="small"/>),
							active:location.pathname==='/app/apps/meta/flight',
							onClick:()=>{
								History.push('/app/apps/meta/flight')
							},
						},
						{
							title: "Hotel Bookings",
							icon : (<BookIcon size="small"/>),
							active:location.pathname===('/app/apps/meta/hotel') || location.pathname.includes('/app/apps/meta/hotel/'),
							onClick:()=>{
								History.push('/app/apps/meta/hotel')
							},
						},
						...OFFLINE && IsSupport?[{
							title: "Hotel Queue",
							icon : (<QueuesIcon size="small"/>),
							active:location.pathname.includes('/app/apps/meta/hotelQueue'),
							onClick:()=>{
								History.push('/app/apps/meta/hotelQueue/requested')
							},
						}]:[],
						{
							title: "Bus Bookings",
							icon : (<BookIcon size="small"/>),
							active:location.pathname==='/app/apps/meta/bus',
							onClick:()=>{
								History.push('/app/apps/meta/bus')
							},
						},
						{
							title: "Train Bookings",
							icon : (<BookIcon size="small"/>),
							active:location.pathname==='/app/apps/meta/train',
							onClick:()=>{
								History.push('/app/apps/meta/train')
							},
						},
						{
							title: "Visa Bookings",
							icon : (<BookIcon size="small"/>),
							active:location.pathname==='/app/apps/meta/visa',
							onClick:()=>{
								History.push('/app/apps/meta/visa')
							},
						},
						{
							title: "Insurance Bookings",
							icon : (<BookIcon size="small"/>),
							active:location.pathname==='/app/apps/meta/insurance',
							onClick:()=>{
								History.push('/app/apps/meta/insurance')
							},
						},
						...OFFLINE && IsSupport?[{
							title: "Train Queue",
							icon : (<QueuesIcon size="small"/>),
							active:location.pathname==='/app/apps/meta/trainQueue',
							onClick:()=>{
								History.push('/app/apps/meta/trainQueue')
							},
						}]:[],
						...OFFLINE && IsSupport?[{
							title: "Visa Queue",
							icon : (<QueuesIcon size="small"/>),
							active:location.pathname==='/app/apps/meta/visaQueue',
							onClick:()=>{
								History.push('/app/apps/meta/visaQueue')
							},
						}]:[],
						...LOCAL_DA?[
							{
								title: "DA Mis",
								icon : (<QueuesIcon size="small"/>),
								active:location.pathname==='/app/apps/meta/da',
								onClick:()=>{
									History.push('/app/apps/meta/da')
								},
							},
							(isReports || IsSupport) && {
								title: "PG Mis",
								icon : (<QueuesIcon size="small"/>),
								active:location.pathname==='/app/apps/meta/pg',
								onClick:()=>{
									History.push('/app/apps/meta/pg')
								},
							}
						]:[
							(isReports || IsSupport) && {
								title: "PG Mis",
								icon : (<QueuesIcon size="small"/>),
								active:location.pathname==='/app/apps/meta/pg',
								onClick:()=>{
									History.push('/app/apps/meta/pg')
								},
							}
						],
						{
							title: "Import Bookings",
							icon : (<BookIcon size="small"/>),
							active:location.pathname==='/app/apps/meta/import',
							onClick:()=>{
								History.push('/app/apps/meta/import')
							},

						},
						{
							title: "All Bookings",
							icon : (<BookIcon size="small"/>),
							active:location.pathname==='/app/apps/meta/all',
							onClick:()=>{
								History.push('/app/apps/meta/all')
							},
						},
						{
							title: "All Utility",
							icon : (<BookIcon size="small"/>),
							active:location.pathname==='/app/apps/meta/utilities',
							onClick:()=>{
								History.push('/app/apps/meta/utilities')
							},
						},
						{
							title: "Transaction",
							icon : (<FileIcon size="small"/>),
							active:location.pathname==='/app/apps/meta/transaction',
							onClick:()=>{
								History.push('/app/apps/meta/transaction')
							},
						},
					]
				}
			]}/>)}
			<Switch>
				<Route path='/app/apps/meta' exact>
					<Wrapper>
						{/* <Dashboard/> */}
						<Transaction/>
					</Wrapper>
				</Route>
				<Route path='/app/apps/meta/trainQueue'>
					<TrainQueue/>
				</Route>
				<Route path='/app/apps/meta/visaQueue'>
					<VisaQueue/>
				</Route>
				<Route path='/app/apps/meta/train'>
					<Wrapper>
						<Train/>
					</Wrapper>
				</Route>
				<Route path='/app/apps/meta/flight'>
					<Wrapper>
						<Flight/>
					</Wrapper>
				</Route>
				<Route path='/app/apps/meta/hotel'>
					<Wrapper>
						<Hotel/>
					</Wrapper>
				</Route>
				<Route path='/app/apps/meta/hotelQueue'>
					<HotelQueue/>
				</Route>
				<Route path='/app/apps/meta/bus'>
					<Wrapper>
						<Bus/>
					</Wrapper>
				</Route>
				<Route path='/app/apps/meta/da'>
					<Wrapper>
						<Da/>
					</Wrapper>
				</Route>
				<Route path='/app/apps/meta/insurance'>
					<Wrapper>
						<Insurance/>
					</Wrapper>
				</Route>
				<Route path='/app/apps/meta/visa'>
					<Wrapper>
						<Visa/>
					</Wrapper>
				</Route>
				<Route path='/app/apps/meta/pg'>
					<Wrapper>
						<Pg/>
					</Wrapper>
				</Route>
				<Route path='/app/apps/meta/all'>
					<Wrapper>
						<All/>
					</Wrapper>
				</Route>
				<Route path='/app/apps/meta/import'>
					<Wrapper>
						{/* <All/> */}
						<Import/>
					</Wrapper>
				</Route>
				<Route path='/app/apps/meta/utilities'>
					<Wrapper>
						<Utility/>
					</Wrapper>
				</Route>
				<Route exact path='/app/apps/meta/transaction'>
					<Wrapper>
						<Transaction/>
					</Wrapper>
				</Route>
				<Route path='/app/apps/meta/transaction/:transId'>
					<Wrapper>
						<TransactionDetails/>
					</Wrapper>
				</Route>
				<NotFound/>
			</Switch>
		</div>
	)
}