import moment from "moment";

const HOTELCHAINS = [
	'Other',
	'Accor Hotel',
	'Accord Hotels & Resorts',
	'Best Western',
	'BLOOMROOMS HOTEL',
	'FIDATO HOTEL',
	'Fortune hotels',
	'HILTON',
	'HYATT HOTEL',
	'Icon Bhagini',
	'IHG Hotels & Resorts',
	'Jaypee Hotels',
	'Lemon tree',
	'Pride Group',
	'Radisson',
	'ROYAL ORCHID HOTELS',
	'Sarovar',
	'Spree Hotel',
	'Suba Group/Choice Hotels',
	'THE CLARK HOTELS & RESORT',
	'The Dolphin Palace',
	'THE FERN HOTELS',
	'The Orchid',
	'THE PARK HOTEL',
	'The Residency',
	'Treebo',
	'Wyndham'
]

const MEALPLAN = [
	'Room Only',
	'Continental Plan',
	'Modified American Plan',
	'American Plan'
]

const HOTELCATEGORY = [
	"Base",
	"Second",
	"AdHoc"
]

const checkDate = (item) => {
    const givenDate = item.searchRequest?.dates?.in;
    if(!givenDate){return false};
    const givenMoment = moment(givenDate, "DD-MM-YYYY");
    if (!givenMoment.isValid()) {
      return false;
    }
    const now = moment();
    const twentyFourHoursLater = moment().add(24, "hours");
  
    if (givenMoment.isBefore(now, "day") || (givenMoment.isBefore(twentyFourHoursLater))) {
      return true;
    }else {
      return false;
    }
  };

const Highlight = ({item,apiQueue=false}) => {
	if(item.checkIn< moment().add('1','d').valueOf()){
		return (
			<div style={{color:'red'}}>
				{item.requestId}
			</div>
		)
	}
	if(apiQueue){
		if(checkDate(item)){
			return (
				<div style={{color:'red'}}>
					{item.referenceId}
				</div>
			)
		}else{
			return <>{item.referenceId}</>
		}
	}
	return <>
		{item.requestId}
	</>
}


// export const hotelTaxesBreakup = (price) => {
//     if (price < 7500) {
// 		let basePrice = Math.floor(price / 1.12);
//         let taxes = Math.floor(price - basePrice);
//         return { basePrice: basePrice.toFixed(2), taxes: taxes.toFixed(2) };
//     } else if (price >= 7500) {
//         let taxes = (price * 0.18) / 1.18;
//         let basePrice = price - taxes;
//         return { basePrice: basePrice.toFixed(2), taxes: taxes.toFixed(2) };
//     } else {
//         return { basePrice: 0, taxes: 0 };
//     }
// };
export function hotelTaxesBreakup(totalPrice) {
    let taxRate;
    taxRate = 0.18;

    const basePrice = totalPrice / (1 + taxRate);
    const taxes = totalPrice - basePrice;

    return {
        basePrice: basePrice.toFixed(2),
        taxes: taxes.toFixed(2)
    };
}



export {
	HOTELCHAINS,MEALPLAN,Highlight,HOTELCATEGORY
}
