import Moment from "moment/moment";
import { DownloadCsv } from "retro/Utils";

// Report of all flights based on segments, for one way show the details in one row and for round flights make two row, one for onwards and one for returns

const transId = (item) => {
  if (item?.transId) {
    let transId = item.transId;
    if (!transId.includes("RI")) transId = "RI" + transId;
    return transId;
  } else {
    return item?.referenceId || "-";
  }
};

// get the name of all travellers
let traveller = (item) => {
  let string = undefined;
  try {
    item.ticket.travellers.forEach((traveller) => {
      if (!string) {
        string = `${traveller.name}`;
      } else {
        string = `${string} , ${traveller.name}`;
      }
    });
  } catch (e) {
    return "-";
  }
  return string;
};

// list of travellers who have status of refunded in any of there flight sequences
let refundedTraveller = (item)=>{
         
  let set = {};
  let string = undefined;
  try {
    item.ticket.travellers.forEach((traveller) => {
      traveller.allSequences.forEach((sequence) => {
        if(sequence.status==="REFUNDED"){
          set[traveller.name] = traveller.name;
        }
      });
    });
    Object.values(set).forEach((value) => {
      if (!string) {
        string = `${value}`;
      } else {
        string = `${string} , ${value}`;
      }
    });
  } catch (e) {
    return "-";
  }
  return string;
}

// concatenate the airlines names in segments
// for one way = use segments in tickes
// for round = use onwards and returns in tickets for airlines
let airlines = (segments) => {
  let string = undefined;
  try {
    segments.forEach((segment) => {
      if (!string) {
        string = `${segment.airline}`;
      } else {
        string = `${string} , ${segment.airline}`;
      }
    });
  } catch (e) {
    return "-";
  }
  return string;
};


// list of all airlines in which there is atleast one status of refunded in case of round partial
let refundedAirlines = (item)=>{
  let string = undefined;
  let refundedSegmentsString = refundedSegments(item);
  let segments = item.ticket.segments;
  try {
    segments.forEach((segment) => {
      let code = `${segment.departure.code}-${segment.arrival.code}`;
      if(refundedSegmentsString.includes(code)){
        if (!string) {
          string = `${segment.airline}`;
        } else {
          string = `${string} , ${segment.airline}`;
        }
      }
    });
  } catch (e) {
    return "-";
  }
  return string;
}

let segments = (segments) => {
  let string = undefined;
  try {
    segments.forEach((segment) => {
      if (!string) {
        string = `${segment.departure.code}-${segment.arrival.code}`;
      } else {
        string = `${string} , ${segment.departure.code}-${segment.arrival.code}`;
      }
    });
  } catch (e) {
    return "-";
  }
  return string;
};

// this function checks whether any sequence in sequences in travellers whose status is refunded belongs to onwards flight or return flight
// using sector in allSequences data 
// here segment parameter accepts item.ticket.onwards to check whether any sequence having status as refunded belongs to onwards flight or not
// same segment parameter accepts item.ticket.returns to check whether any sequence having status as refunded belongs to return flight or not
let checkRefundedSegments = (item,segment)=>{
     
  let set = {};
  let string = undefined;
  // segmentString will be the string of all onwards flight segments if segments param is item.ticket.onward
  // and it will be string of all return flight if segments parameter is item.ticket.returns
  let segmentString = segments(segment)
  let segmentFound = false;
  try {
    item.ticket.travellers.forEach((traveller) => {
      traveller.allSequences.forEach((sequence) => {
        if(sequence.status==="REFUNDED"){
          set[sequence.sector] = sequence.sector;
        }
      });
    });
    Object.values(set).forEach((value) => {
      if(segmentString.includes(value)){  
        segmentFound = true;
      }
    });
  } catch (e) {
    return false;
  }
  return segmentFound;
}

let pnrWithSegments = (item, segments = []) => {
  let set = {};
  let string = undefined;
  // store in set to avoid duplicate pnrs
  try {
    item.ticket.travellers.forEach((traveller) => {
      segments.forEach((segment) => {
        let code = `${segment.departure.code}-${segment.arrival.code}`;
        let pnr = traveller.pnrs[code];
        set[pnr] = pnr;
      });
    });
    Object.values(set).forEach((value) => {
      if (!string) {
        string = `${value}`;
      } else {
        string = `${string} , ${value}`;
      }
    });
  } catch (e) {
    return "-";
  }
  return string;
};


// to find all the pnrs of travellers who have partial refunded status
let refundedPnrs = (item)=>{
  let set = {};
  let string = undefined;
  try {
    item.ticket.travellers.forEach((traveller) => {
      traveller.allSequences.forEach((sequence) => {
        let pnr = traveller.pnrs[sequence.sector];
        set[pnr] = pnr;
      });
    });
    Object.values(set).forEach((value) => {
      if (!string) {
        string = `${value}`;
      } else {
        string = `${string} , ${value}`;
      }
    });
  } catch (e) {
    return "-";
  }
  return string;
}

let findSector = (segments)=>{
  let sector = undefined;
  if(!segments)return ""
  sector = `${segments?.[0]?.departure?.code}-${segments?.[segments.length - 1]?.arrival?.code}`
  return sector;
}

let calculateFareBreakup = (travellers,sector)=>{
  let fareBreakup = {
    airlineCharges: 0,
    platformCharges: 0,
    refundAmount: 0
  };
  try{
     travellers.forEach((traveller)=>{
      traveller.allSequences.forEach((sequence)=>{
        if(sequence.sector === sector ){
          fareBreakup.airlineCharges += sequence?.airlineCharges || 0,
          fareBreakup.platformCharges += sequence?.platformCharges || 0,
          fareBreakup.refundAmount += sequence?.refundAmount || 0
        }
      })
    })
    return fareBreakup;
  }catch(e){
    return fareBreakup;
  }
  return fareBreakup;
}

// sector is onward and return sector based on parameter
// it checks whether onward flight is refunded or not
let isCancelled = (travellers,sector)=>{
  let cancelled = false;
  travellers.forEach((traveller)=>{
    traveller?.allSequences?.forEach((sequence)=>{
      if(sequence.sector === sector && sequence.status==="REFUNDED"){
        cancelled = true;
      }
    })
  })
  return cancelled;
}

// concatenate the tickets of travellers on the basis of segments
let ticketWithSegments = (item, segments = []) => {
  let set = {};
  let string = undefined;
  try {
    item.ticket.travellers.forEach((traveller) => {
      segments.forEach((segment) => {
        let code = `${segment.departure.code}-${segment.arrival.code}`;
        let tck = traveller.tickets[code];
        set[tck] = tck;
      });
    });
    Object.values(set).forEach((value) => {
      if (!string) {
        string = `${value}`;
      } else {
        string = `${string} , ${value}`;
      }
    });
  } catch (e) {
    return "-";
  }
  return string;
};

let refundedTickets = (item) => {
  let set = {};
  let string = undefined;
  try {
    item.ticket.travellers.forEach((traveller) => {
      traveller.allSequences.forEach((sequence) => {
        let tck = traveller.tickets[sequence.sector];
        set[tck] = tck;
      });
    });
    Object.values(set).forEach((value) => {
      if (!string) {
        string = `${value}`;
      } else {
        string = `${string} , ${value}`;
      }
    });
  } catch (e) {
    return "-";
  }
  return string;
};

let docType = (item) => {
  if (item.invoiceConfig?.reseller) {
    return [
      "Invoice",
      item.invoiceConfig?.invoiceNo || "-",
      item.invoiceConfig?.invoice || "-",
    ];
  }
  if (
    item.invoiceConfig != null &&
    item.invoiceConfig.serviceInvoice != null &&
    item.invoiceConfig.serviceInvoice !== "SKIPPED"
  ) {
    return [
      "Service Invoice",
      item.invoiceConfig?.serviceInvoiceNo || "-",
      item.invoiceConfig?.serviceInvoice || "-",
    ];
  }
  if (
    item.invoiceConfig !== null &&
    item.invoiceConfig.creditNote !== null &&
    item.invoiceConfig.creditNote !== "PENDING"
  ) {
    return [
      "Credit Note",
      item.invoiceConfig?.crnNo || "-",
      item.invoiceConfig?.creditNote || "-",
    ];
  }
  if (
    item.invoiceConfig !== null &&
    item.invoiceConfig.cancellationCharges !== null &&
    item.invoiceConfig.cancellationCharges !== "PENDING"
  ) {
    return [
      "Cancellation",
      item?.invoiceConfig?.cancellationInvoiceNo || "-",
      item.invoiceConfig?.cancellationCharges || "-",
    ];
  }
  if (
    item?.invoiceConfig !== null &&
    item?.invoiceConfig?.ancillaryInvoice !== null &&
    item?.invoiceConfig?.ancillaryInvoice !== "SKIPPED"
  ) {
    // let lineItem = JSON.parse(item.invoiceConfig?.ancillaryRequest);
    return [
      "Ancillery Charges",
      // lineItem?.data?.docDtls?.no || "-",
      "-",
      item?.invoiceConfig?.ancillaryFare?.total || "-",
    ];
  }
  return ["-","-","-"]
};

export function downloadPendingSegmentSettlement(items, Dates) {
  let data = [];
  data.push([
    "Client Name",
    "Client Id",
    "Buyer Gst",
    "Customer Code",
    "Flight Segments",
    "Trip Creation Date",
    "RI Id",
    "Traveller Name",
    "Airline Name",
    "Pnr Number",
    "Ticket Number",
    "Cabin Class",
    "Fare Type",
    "Travel Start Date",
    "Travel End Date",
    "Journey Type",
    "Booking Status",
    "Document Type",
    "Document Number",
    "Document URL",
    //Ticket fare,
    // 'Voucher URL',
    "Base Fare",
    "Other Charges",
    "Gst",
    "Total Fare/Refund",
    "CT Charges", // new field
    "Airline cancellation fees",
    "SEZ"
  ]);
  items.forEach((item) => {
    if (item.status === "FAILED") return;
    if (item.status === "ONGOING") return;
    if (item.category) {
      if (item.category === "DOMESTICFLIGHT") {
        data.push([
          item?.client?.name || "-",
          item?.client?.id || "-",
          item?.invoiceConfig?.userGst?.gstin || '-',
          item?.client?.clientCode || '-',
          // item?.provider || 'Cleartrip Packages and Tours Private Limited',
          segments(item?.ticket?.segments) || "-",
          Moment(item?.createdAt).format("DD MMM YYYY hh:mm A") || "-",
          item?.referenceId || "-",
          traveller(item) || "-",
          airlines(item?.ticket?.segments) || "-",
          pnrWithSegments(item, item?.ticket?.segments) || "-",
          ticketWithSegments(item, item?.ticket?.segments) || "-",
          item?.ticket?.cabinClass || "ECONOMY",
          item?.ticket?.fareType || "-", //fare type
          item.ticket?.segments && item.ticket?.segments.length
            ? Moment(item.ticket.segments[0].departureTime).format(
                "DD MMM YYYY hh:mm A"
              )
            : "-",
          item?.ticket?.segments && item?.ticket?.segments.length
            ? Moment(
                item.ticket.segments[item.ticket.segments.length - 1]
                  .arrivalTime
              ).format("DD MMM YYYY hh:mm A")
            : "-",
          item?.category?.toUpperCase() === "DOMESTICFLIGHT"
            ? "One Way"
            : "Round",
          "BOOKING",
          // ...docType(item),
          "Service Invoice",
          item.invoiceConfig?.serviceInvoiceNo || "-",
          item.invoiceConfig?.serviceInvoice || "-",
          item?.ticket?.recko?.onwardFlight?.baseFare || "-",
          item?.ticket?.recko?.onwardFlight?.otherCharges || "-",
          item?.ticket?.recko?.onwardFlight?.gst || "-",
          item?.ticket?.recko?.onwardFlight?.total || "-",
          item?.invoiceConfig?.serviceCharges || "-",
          // airline cancellaion
          '0',
          item?.invoiceConfig?.sez === undefined ? "-" : item?.invoiceConfig?.sez === false ? "No" : "Yes",
        ]);
        if (item.status === "REFUNDED") {
          data.push([
            item?.client?.name || "-",
            item?.client?.id || "-",
            item?.invoiceConfig?.userGst?.gstin || '-',
            item?.client?.clientCode || '-',
            // item?.provider || 'Cleartrip Packages and Tours Private Limited',
            segments(item?.ticket?.segments) || "-",
            Moment(item?.createdAt).format("DD MMM YYYY hh:mm A") || "-",
            item?.referenceId || "-",
            traveller(item) || "-",
            airlines(item?.ticket?.segments) || "-",
            pnrWithSegments(item, item?.ticket?.segments),
            ticketWithSegments(item, item?.ticket?.segments) || "-",
            item?.ticket?.cabinClass || "ECONOMY",
            item?.ticket?.fareType || "-", //fare type
            item?.ticket?.segments && item?.ticket?.segments.length
              ? Moment(item.ticket.segments[0].departureTime).format(
                  "DD MMM YYYY hh:mm A"
                )
              : "-",
            item?.ticket?.segments && item?.ticket?.segments.length
              ? Moment(
                  item.ticket.segments[item.ticket.segments.length - 1]
                    .arrivalTime
                ).format("DD MMM YYYY hh:mm A")
              : "-",
            item?.category?.toUpperCase() === "DOMESTICFLIGHT"
              ? "One Way"
              : "Round",
              "REFUNDED",
            // ...docType(item),
            "Credit Note",
            item.invoiceConfig?.crnNo || "-",
            item.invoiceConfig?.creditNote || "-",
            // item?.ticket?.recko?.onwardFlight?.baseFare || "-",
            // item?.ticket?.recko?.onwardFlight?.otherCharges || "-",
            // item?.ticket?.recko?.onwardFlight?.gst || "-",
            '-',
            '-',
            '-',
            (item?.refundAmount || 0) + (item?.invoiceConfig?.platformCancellationCharges || 0 ) || "-", // Total Fare
            item?.invoiceConfig?.platformCancellationCharges || "-", // ct charges
            (item?.amount || 0) - (item?.refundAmount || 0) - (item?.invoiceConfig?.platformCancellationCharges || 0) - (item?.invoiceConfig?.serviceCharges || 0) || "-",  // airline cancellation fees
            item?.invoiceConfig?.sez === undefined ? "-" : item?.invoiceConfig?.sez === false ? "No" : "Yes",
          ]);
        }
        if (item.status === "PARTIAL") {
          data.push([
            item?.client?.name || "-",
            item?.client?.id || "-",
            item?.invoiceConfig?.userGst?.gstin || '-',
            item?.client?.clientCode || '-',
            // item?.provider || 'Cleartrip Packages and Tours Private Limited',
            segments(item?.ticket?.segments) || "-",
            Moment(item?.createdAt).format("DD MMM YYYY hh:mm A") || "-",
            item?.referenceId || "-",
            traveller(item) || "-",
            airlines(item?.ticket?.segments) || "-",
            pnrWithSegments(item, item?.ticket?.segments),
            ticketWithSegments(item, item?.ticket?.segments) || "-",
            item?.ticket?.cabinClass || "ECONOMY",
            item?.ticket?.fareType || "-", //fare type
            item?.ticket?.segments && item.ticket.segments.length
              ? Moment(item.ticket.segments[0].departureTime).format(
                  "DD MMM YYYY hh:mm A"
                )
              : "-",
            item?.ticket?.segments && item?.ticket?.segments.length
              ? Moment(
                  item.ticket.segments[item.ticket.segments.length - 1]
                    .arrivalTime
                ).format("DD MMM YYYY hh:mm A")
              : "-",
            item?.category?.toUpperCase() === "DOMESTICFLIGHT"
              ? "One Way"
              : "Round",
            "PARTIAL",
            ...docType(item),
            item?.ticket?.recko?.onwardFlight?.baseFare || "-",
            item?.ticket?.recko?.onwardFlight?.otherCharges || "-",
            item?.ticket?.recko?.onwardFlight?.gst || "-",
            (item?.ticket?.recko?.onwardFlight?.total || 0) + (item?.invoiceConfig?.platformCancellationCharges || 0) || "-",
            item?.invoiceConfig?.platformCancellationCharges || "-",
            // (item?.amount || 0) - (item?.refundAmount || 0) - (item?.invoiceConfig?.platformCancellationCharges || 0) || "-",
            // item?.amount - item?.invoiceConfig?.serviceCharges || "-",
            // airline cancellaion
            (item?.amount || 0) - (item?.refundAmount || 0) - (item?.invoiceConfig?.platformCancellationCharges || 0) - (item?.invoiceConfig?.serviceCharges || 0) || "-",
            item?.invoiceConfig?.sez === undefined ? "-" : item?.invoiceConfig?.sez === false ? "No" : "Yes",
          ]);
        }
      }
      if (item.category === "DOMESTICROUNDFLIGHT") {
        // onwards data
        data.push([
          item?.client?.name || "-",
          item?.client?.id || "-",
          item?.invoiceConfig?.userGst?.gstin || '-',
          item?.client?.clientCode || '-',
          // item?.provider || 'Cleartrip Packages and Tours Private Limited',
          segments(item?.ticket?.onwards) || "-",
          Moment(item?.createdAt).format("DD MMM YYYY hh:mm A") || "-",
          item?.referenceId || "-",
          traveller(item) || "-",
          airlines(item?.ticket?.onwards) || "-",
          pnrWithSegments(item, item?.ticket?.onwards),
          ticketWithSegments(item, item?.ticket?.onwards) || "-",
          item?.ticket?.cabinClass || "ECONOMY",
          item?.ticket?.fareType || "-", //fare type
          item.ticket.segments && item.ticket.segments.length
            ? Moment(item.ticket.segments[0].departureTime).format(
                "DD MMM YYYY hh:mm A"
              )
            : "-",
          item?.ticket?.segments && item.ticket.segments.length
            ? Moment(
                item.ticket.segments[item.ticket.segments.length - 1]
                  .arrivalTime
              ).format("DD MMM YYYY hh:mm A")
            : "-",
          item?.category?.toUpperCase() === "DOMESTICFLIGHT"
            ? "One Way"
            : "Round",
          "BOOKING",
          ...docType(item),
          item?.ticket?.recko?.onwardFlight?.baseFare || "-",
          item?.ticket?.recko?.onwardFlight?.otherCharges || "-",
          item?.ticket?.recko?.onwardFlight?.gst || "-",
          item?.ticket?.recko?.onwardFlight?.total || "-",
          item?.invoiceConfig?.serviceCharges/2 || "-",
          "0",
          item?.invoiceConfig?.sez === undefined ? "-" : item?.invoiceConfig?.sez === false ? "No" : "Yes",
        ]);
        // return data
        data.push([
          item?.client?.name || "-",
          item?.client?.id || "-",
          item?.invoiceConfig?.userGst?.gstin || '-',
          item?.client?.clientCode || '-',
          // item?.provider || 'Cleartrip Packages and Tours Private Limited',
          segments(item?.ticket?.returns) || "-",
          Moment(item?.createdAt).format("DD MMM YYYY hh:mm A") || "-",
          item?.referenceId || "-",
          traveller(item) || "-",
          airlines(item?.ticket?.returns) || "-",
          pnrWithSegments(item, item?.ticket?.returns) || "-",
          ticketWithSegments(item, item?.ticket?.returns) || "-",
          item?.ticket?.cabinClass || "ECONOMY",
          item?.ticket?.fareType || "-", //fare type
          item?.ticket?.segments && item.ticket.segments.length
            ? Moment(item.ticket.segments[0].departureTime).format(
                "DD MMM YYYY hh:mm A"
              )
            : "-",
          item?.ticket?.segments && item.ticket.segments.length
            ? Moment(
                item.ticket.segments[item.ticket.segments.length - 1]
                  .arrivalTime
              ).format("DD MMM YYYY hh:mm A")
            : "-",
          item?.category?.toUpperCase() === "DOMESTICFLIGHT"
            ? "One Way"
            : "Round",
          "BOOKING",
          ...docType(item),
          // item?.amount - item?.invoiceConfig?.serviceCharges || "-",
          // airline cancellation fee
          // "-",
          // "-",
          // '-',
          // '-',
          // '-',
          item?.ticket?.recko?.returnFlight?.baseFare || "-",
          item?.ticket?.recko?.returnFlight?.otherCharges || "-",
          item?.ticket?.recko?.returnFlight?.gst || "-",
          item?.ticket?.recko?.returnFlight?.total || "-",
          item?.invoiceConfig?.serviceCharges/2 || "-",
          "-",
          item?.invoiceConfig?.sez === undefined ? "-" : item?.invoiceConfig?.sez === false ? "No" : "Yes",
          // (item?.amount || 0) - (item?.refundAmount || 0) - (item?.invoiceConfig?.platformCancellationCharges || 0) || "-",
        ]);

        if ((item.status === "REFUNDED")) {
          // onwards refunded data
          const onwardsSector = findSector(item.ticket.onwards);
          const onwardFareBreakup = calculateFareBreakup(item?.ticket?.travellers,onwardsSector);
          data.push([
            item?.client?.name || "-",
            item?.client?.id || "-",
            item?.invoiceConfig?.userGst?.gstin || '-',
            item?.client?.clientCode || '-',
            // item?.provider || 'Cleartrip Packages and Tours Private Limited',
            segments(item?.ticket?.onwards) || "-",
            Moment(item?.createdAt).format("DD MMM YYYY hh:mm A") || "-",
            item?.referenceId || "-",
            traveller(item) || "-",
            airlines(item.ticket.onwards) || "-",
            pnrWithSegments(item, item?.ticket?.onwards) || "-",
            ticketWithSegments(item, item?.ticket?.onwards) || "-",
            item?.ticket?.cabinClass || "ECONOMY",
            item?.ticket?.fareType || "-", //fare type
            item?.ticket?.segments && item.ticket.segments.length
              ? Moment(item.ticket.segments[0].departureTime).format(
                  "DD MMM YYYY hh:mm A"
                )
              : "-",
            item?.ticket?.segments && item.ticket.segments.length
              ? Moment(
                  item.ticket.segments[item.ticket.segments.length - 1]
                    .arrivalTime
                ).format("DD MMM YYYY hh:mm A")
              : "-",
            item?.category?.toUpperCase() === "DOMESTICFLIGHT"
              ? "One Way"
              : "Round",
            "REFUNDED",
            // ...docType(item),
            "Credit Note",
            item.invoiceConfig?.crnNo || "-",
            item.invoiceConfig?.creditNote || "-",
            // item?.amount - item?.invoiceConfig?.serviceCharges || "-",
            // item?.ticket?.recko?.onwardFlight?.baseFare || "-",
            // item?.ticket?.recko?.onwardFlight?.otherCharges || "-",
            // item?.ticket?.recko?.onwardFlight?.gst || "-",
            '0',
            '0',
            '0',
            (onwardFareBreakup?.refundAmount || 0) + (onwardFareBreakup?.platformCharges || 0)  || "-",
            onwardFareBreakup?.platformCharges || "-",
            onwardFareBreakup?.airlineCharges || '-',
            item?.invoiceConfig?.sez === undefined ? "-" : item?.invoiceConfig?.sez === false ? "No" : "Yes",
            // item?.ticket?.recko?.onwardFlight?.total || "-",
            // item?.invoiceConfig?.serviceCharges/2 || "-",
            // (item?.amount || 0) - (item?.refundAmount || 0) - (item?.invoiceConfig?.platformCancellationCharges || 0) || "-",
          ]);
          // return refunded data
          let returnSector = findSector(item?.ticket?.returns);
          let returnFareBreakup = calculateFareBreakup(item?.ticket?.travellers,returnSector);
          data.push([
            item?.client?.name || "-",
            item?.client?.id || "-",
            item?.invoiceConfig?.userGst?.gstin || '-',
            item?.client?.clientCode || '-',
            // item?.provider || 'Cleartrip Packages and Tours Private Limited',
            segments(item?.ticket?.returns) || "-",
            Moment(item?.createdAt).format("DD MMM YYYY hh:mm A") || "-",
            item?.referenceId || "-",
            traveller(item) || "-",
            airlines(item.ticket.returns) || "-",
            pnrWithSegments(item, item?.ticket?.returns) || "-",
            ticketWithSegments(item, item?.ticket?.returns) || "-",
            item?.ticket?.cabinClass || "ECONOMY",
            item?.ticket?.fareType || "-", //fare type
            item?.ticket?.segments && item.ticket.segments.length
              ? Moment(item.ticket.segments[0].departureTime).format(
                  "DD MMM YYYY hh:mm A"
                )
              : "-",
            item?.ticket?.segments && item.ticket.segments.length
              ? Moment(
                  item.ticket.segments[item.ticket.segments.length - 1]
                    .arrivalTime
                ).format("DD MMM YYYY hh:mm A")
              : "-",
            item?.category?.toUpperCase() === "DOMESTICFLIGHT"
              ? "One Way"
              : "Round",
            "REFUNDED",
            // ...docType(item),
            "Credit Note",
            item.invoiceConfig?.crnNo || "-",
            item.invoiceConfig?.creditNote || "-",
            "-",
            "-",
            '-',
            (returnFareBreakup?.refundAmount || 0) + ( returnFareBreakup?.platformCharges || 0) || "-",
            returnFareBreakup?.platformCharges || "-",
            returnFareBreakup?.airlineCharges || '-',
            item?.invoiceConfig?.sez === undefined ? "-" : item?.invoiceConfig?.sez === false ? "No" : "Yes",
            // "0",
            // "0",
            // "0",
            // item?.ticket?.recko?.returnFlight?.total || "-",
            // item?.invoiceConfig?.serviceCharges/2 || "-",
            // (item?.amount || 0) - (item?.refundAmount || 0) - (item?.invoiceConfig?.platformCancellationCharges || 0) || "-",
          ]);
        }
        if ((item.status === "PARTIAL")) {
          // onwards data for partial round flight
          // checking whether onwards flight is refunded or return 
          // if first element of allSequence's status is Refunded then it means onwards filght is refunded in partial status
          // else return flight
          // we want to show the data of only refunded flight in partial booking

          let onwardSector = findSector(item?.ticket?.onwards);
          let onwardFareBreakup = calculateFareBreakup(item?.ticket?.travellers,onwardSector);
          // if(checkRefundedSegments(item,item?.ticket?.onwards)){
          if(isCancelled(item?.ticket?.travellers,onwardSector)){
            data.push([
              item?.client?.name || "-",
              item?.client?.id || "-",
              item?.invoiceConfig?.userGst?.gstin || '-',
              item?.client?.clientCode || '-',
              // item?.provider || 'Cleartrip Packages and Tours Private Limited',
              segments(item?.ticket?.onwards) || "-",
              Moment(item?.createdAt).format("DD MMM YYYY hh:mm A") || "-",
              item?.referenceId || "-",
              traveller(item) || "-",
              airlines(item?.ticket?.onwards) || "-",
              pnrWithSegments(item, item?.ticket?.onwards),
              ticketWithSegments(item, item?.ticket?.onwards) || "-",
              item?.ticket?.cabinClass || "ECONOMY",
              item?.ticket?.fareType || "-", //fare type
              item?.ticket?.segments && item.ticket.segments.length
                ? Moment(item.ticket.segments[0].departureTime).format(
                    "DD MMM YYYY hh:mm A"
                  )
                : "-",
              item?.ticket?.segments && item.ticket.segments.length
                ? Moment(
                    item.ticket.segments[item.ticket.segments.length - 1]
                      .arrivalTime
                  ).format("DD MMM YYYY hh:mm A")
                : "-",
              item?.category?.toUpperCase() === "DOMESTICFLIGHT"
                ? "One Way"
                : "Round",
              "PARTIAL",
              // ...docType(item),
              "Credit Note",
              item.invoiceConfig?.crnNo || "-",
              item.invoiceConfig?.creditNote || "-",
              // item?.ticket?.recko?.onwardFlight?.baseFare || "-",
              // item?.ticket?.recko?.onwardFlight?.otherCharges || "-",
              // item?.ticket?.recko?.onwardFlight?.gst || "-",
              '-',
              '-',
              '-',
              (onwardFareBreakup?.refundAmount || 0) + (onwardFareBreakup?.platformCharges || 0)  || "-",
              onwardFareBreakup?.platformCharges || "-",
              onwardFareBreakup?.airlineCharges || '-',
              item?.invoiceConfig?.sez === undefined ? "-" : item?.invoiceConfig?.sez === false ? "No" : "Yes",
            ]);
          }
          let returnSector = findSector(item?.ticket?.returns);
          let returnFareBreakup = calculateFareBreakup(item?.ticket?.travellers,returnSector);
          // if(checkRefundedSegments(item,item.ticket.returns)){
          if(isCancelled(item?.ticket?.travellers,returnSector)){
            data.push([
              item?.client?.name || "-",
              item?.client?.id || "-",
              item?.invoiceConfig?.userGst?.gstin || '-',
              item?.client?.clientCode || '-',
              // item?.provider || 'Cleartrip Packages and Tours Private Limited',
              segments(item?.ticket?.returns) || "-",
              Moment(item?.createdAt).format("DD MMM YYYY hh:mm A") || "-",
              item?.referenceId || "-",
              traveller(item) || "-",
              airlines(item.ticket.returns) || "-",
              pnrWithSegments(item, item?.ticket?.returns) || "-",
              ticketWithSegments(item, item?.ticket?.returns) || "-",
              item?.ticket?.cabinClass || "ECONOMY",
              item?.ticket?.fareType || "-", //fare type
              item?.ticket?.segments && item.ticket.segments.length
                ? Moment(item.ticket.segments[0].departureTime).format(
                    "DD MMM YYYY hh:mm A"
                  )
                : "-",
              item?.ticket?.segments && item.ticket.segments.length
                ? Moment(
                    item.ticket.segments[item.ticket.segments.length - 1]
                      .arrivalTime
                  ).format("DD MMM YYYY hh:mm A")
                : "-",
              item?.category?.toUpperCase() === "DOMESTICFLIGHT"
                ? "One Way"
                : "Round",
              "PARTIAL",
              // ...docType(item),
              "Credit Note",
              item.invoiceConfig?.crnNo || "-",
              item.invoiceConfig?.creditNote || "-",
              "-",
              "-",
              '-',
              (returnFareBreakup?.refundAmount || 0) + ( returnFareBreakup?.platformCharges || 0) || "-",
              returnFareBreakup?.platformCharges || "-",
              returnFareBreakup?.airlineCharges || '-',
              item?.invoiceConfig?.sez === undefined ? "-" : item?.invoiceConfig?.sez === false ? "No" : "Yes",
            ]);
          }
        }
      }
    }
  });

  DownloadCsv(
    `Flight_Segment_Settlement_Report_${Moment(Dates.startDate).format(
      "DD_MM_YYYY"
    )}_${Moment(Dates.endDate).format("DD_MM_YYYY")}.csv`,
    data
  );
}
