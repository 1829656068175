import React from 'react'
import Props from 'prop-types'
import './SwitchTab.css'

/**
 *@author [Aakash Bhadana](https://github.com/aakashbhadana)
 *
 * Statelss Tabswith all classes
 */

function SwitchTab ({className="",  active, tabs, onClick, style}) {
    return (
        <div style={{ ...style}} className={`switch-tabs scrollbar-hidden ${className}`}>
            {
                tabs.map((tab,index)=>{
                    return (
                        <div key={index} onClick={()=>onClick(tab)} className={`${active===tab?"switch-tab-option-active":"switch-tab-option"}`}>
                            {tab}
                        </div>
                    )
                })
            }
        </div>
    )
}

SwitchTab.propTypes = {
    /**
     * Use external css classes
     */
    className:Props.string,
    /**
     * Array of Tab labels
     */
    tabs:Props.array,
    /**
     * Label of currently active Tab
     */
    active:Props.string,
    /**
     * Function to change active Tab
     */
    onClick:Props.func,
}

export default SwitchTab
