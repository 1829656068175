import React, { useEffect, useState } from "react";
import { ExpandableSidebar, TOAST as Alert } from "../../../../../../retro";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import Queued from "./Queued";
import ActionQueued from "./ActionQueued";
import Contacted from "./Contacted";
import Assigned from "./Assigned";
import Payment from "./Payment";
import Invoice from "./Invoice";
import InvoiceCollected from "./InvoiceCollected";
import CleartripQueue from "./CleartripQueue";
import { get } from "App/Network/Axios";

export default function index() {
  const History = useHistory();
  const location = useLocation();
  const [assigned, setAssigned] = useState(0);
  const [allPending,setAllPending] = useState(0);
  const [pending, setPending] = useState(0);
  const [contacted, setContacted] = useState(0);
  const [nonContacted, setNonContacted] = useState(0);
  const [payment, setPayment] = useState(0);
  const [invoice, setInvoice] = useState(0);
  const [invoiceColleted, setInvoiceCollected] = useState(0);

  useEffect(() => {
    const load = () => {
      get(
        `/meta/hotel/queue/pending`,
        (e, r) => {
          if (r) {
            setAllPending(r.total);
          }
        },
        {
          offset: 0,
        }
        );
      get(
        `/meta/hotel/queue/assigned`,
        (e, r) => {
          if (r) {
            setAssigned(r.total);
          }
        },
        {
          offset: 0,
        }
      );
      get(
        `/meta/hotel/queue/pending/QUEUED`,
        (e, r) => {
          if (r) {
            setPending(r.total);
          }
        },
        {
          offset: 0,
        }
      );
      get(
        `/meta/hotel/queue/pending/CONTACTED`,
        (e, r) => {
          if (r) {
            setContacted(r.total);
          }
        },
        {
          offset: 0,
        }
      );
      get(
        `/meta/hotel/queue/pending/CLEARTRIP`,
        (e, r) => {
          if (r) {
            setNonContacted(r.total);
          }
        },
        {
          offset: 0,
        }
      );
      get(
        `/meta/hotel/queue/completed/PAYMENT`,
        (e, r) => {
          if (r) {
            setPayment(r.total);
          }
        },
        {
          offset: 0,
        }
      );
      get(
        `/meta/hotel/queue/completed/INVOICE`,
        (e, r) => {
          if (r) {
            setInvoice(r.total);
          }
        },
        {
          offset: 0,
        }
      );
      get(
        `/meta/hotel/queue/completed/COMPLETED`,
        (e, r) => {
          if (r) {
            setInvoiceCollected(r.total);
          }
        },
        {
          offset: 0,
        }
      );
    };

    load();
    const interval = setInterval(() => {
      load();
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div className='flex mt4 h-100h w-100'>
      <ExpandableSidebar
        items={[
          {
            title: "My Actions",
            actions: [
              {
                title:
                  assigned === 0
                    ? "Assigned To Me"
                    : `Assigned To Me (${assigned})`,
                active:
                  location.pathname === "/app/apps/meta/hotelQueue/requested/assigned",
                onClick: () => {
                  History.push("/app/apps/meta/hotelQueue/requested/assigned");
                },
              },
            ],
          },
          {
            title: "All Queue",
            actions: [
              {
                title: !allPending 
                ? "All Pending Requests" 
                : `All Pending Requests (${allPending})`,
                active: location.pathname === "/app/apps/meta/hotelQueue/requested",
                onClick: () => {
                  History.push("/app/apps/meta/hotelQueue/requested");
                },
              },
              {
                title: pending === 0 ? "Unassigned" : `Unassigned (${pending})`,
                active:
                  location.pathname === "/app/apps/meta/hotelQueue/requested/actions",
                onClick: () => {
                  History.push("/app/apps/meta/hotelQueue/requested/actions");
                },
              },
              {
                title:
                  contacted === 0
                    ? "Contacted Hotel"
                    : `Contacted Hotel (${contacted})`,
                active:
                  location.pathname === "/app/apps/meta/hotelQueue/requested/contacted",
                onClick: () => {
                  History.push("/app/apps/meta/hotelQueue/requested/contacted");
                },
              },
              {
                title: nonContacted === 0 
                ? "Non Contracted Queue"
                : `Non Contracted Queue (${nonContacted})`,
                active:
                  location.pathname === "/app/apps/meta/hotelQueue/requested/cleartrip",
                onClick: () => {
                  History.push("/app/apps/meta/hotelQueue/requested/cleartrip");
                },
              },
              {
                title:
                  payment === 0
                    ? "Payment Pending"
                    : `Payment Pending (${payment})`,
                active:
                  location.pathname === "/app/apps/meta/hotelQueue/requested/payments",
                onClick: () => {
                  History.push("/app/apps/meta/hotelQueue/requested/payments");
                },
              },
              {
                title:
                  invoice === 0
                    ? "Invoice Collection"
                    : `Invoice Collection (${invoice})`,
                active:
                  location.pathname === "/app/apps/meta/hotelQueue/requested/invoice",
                onClick: () => {
                  History.push("/app/apps/meta/hotelQueue/requested/invoice");
                },
              },
              {
                title:
                  invoice === 0
                    ? "Invoice Collected"
                    : `Invoice Collected (${invoiceColleted})`,
                active:
                  location.pathname ===
                  "/app/apps/meta/hotelQueue/requested/invoiceCollected",
                onClick: () => {
                  History.push("/app/apps/meta/hotelQueue/requested/invoiceCollected");
                },
              },
            ],
          },
        ]}
      />
      <div
        className='flex-1 overScrollY pd3'
        style={{
          height: "calc(100vh - 100px)",
        }}
      >
        <Switch>
          <Route path='/app/apps/meta/hotelQueue/requested' exact component={Queued} />
          <Route
            path='/app/apps/meta/hotelQueue/requested/actions'
            exact
            component={ActionQueued}
          />
          <Route
            path='/app/apps/meta/hotelQueue/requested/assigned'
            exact
            component={Assigned}
          />
          <Route
            path='/app/apps/meta/hotelQueue/requested/cleartrip'
            exact
            component={CleartripQueue}
          />
          <Route
            path='/app/apps/meta/hotelQueue/requested/contacted'
            exact
            component={Contacted}
          />
          <Route
            path='/app/apps/meta/hotelQueue/requested/payments'
            exact
            component={Payment}
          />
          <Route
            path='/app/apps/meta/hotelQueue/requested/invoice'
            exact
            component={Invoice}
          />
          <Route
            path='/app/apps/meta/hotelQueue/requested/invoiceCollected'
            exact
            component={InvoiceCollected}
          />
        </Switch>
      </div>
    </div>
  );
}
