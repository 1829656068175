import React, { useState } from 'react';
import { Tabs } from 'retro';
import Queued from './Queued';
import Past from './Past';
import ContractedHistory from './ContractedHistory';
import RefundQueue from './RefundQueue';
import { Route, Switch } from 'react-router-dom';
import QueuedAPI from './QueuedAPI';
import OfflineInvoices from './OfflineInvoices'

export default function index() {

  const [totalRequests, setTotalRequests]=useState(0)

  return (
    <div className='flex-1'>
      <Tabs
        style={{ marginBottom: 'calc(-2rem - 1px)' }}
        className=''
        tabs={[
          {
            title: 'Request Queues',
            path: '/app/apps/meta/hotelQueue/requested',
            type: 'includes',
          },
          {
            title: `Request History (${totalRequests})`,
            path: '/app/apps/meta/hotelQueue/past',
          },
          {
            title: 'Hotel Api Queues',
            path: '/app/apps/meta/hotelQueue/apiQueue',
            type: 'includes',
          },
          {
            title: 'Api History',
            path: '/app/apps/meta/hotelQueue/apis/past',
          },
          {
            title: 'Refund Queue',
            path: '/app/apps/meta/hotelQueue/refunds',
          },
          {
            title: 'Online Invoices',
            path: '/app/apps/meta/hotelQueue/offlineinvoices',
          },
        ]}
      />
      <Switch>
        <Route path='/app/apps/meta/hotelQueue/past' exact>
          <div
            className='overScrollY pd6'
            style={{ height: 'calc(100vh - 120px)' }}
          >
            <Past setTotalRequests={setTotalRequests} />
          </div>
        </Route>
        <Route path='/app/apps/meta/hotelQueue/apiQueue'>
          <QueuedAPI />
        </Route>
        <Route path='/app/apps/meta/hotelQueue/apis/past' exact>
          <div
            className='overScrollY pd6'
            style={{ height: 'calc(100vh - 120px)' }}
          >
            <ContractedHistory />
          </div>
        </Route>
        <Route path='/app/apps/meta/hotelQueue/offlineinvoices'>
          <div
            className='overScrollY pd6'
            style={{ height: 'calc(100vh - 120px)' }}
          >
            <OfflineInvoices />
          </div>
        </Route>
        <Route path='/app/apps/meta/hotelQueue/refunds' exact>
          <div
            className='overScrollY pd6'
            style={{ height: 'calc(100vh - 120px)' }}
          >
            <RefundQueue />
          </div>
        </Route>
        <Route path='/app/apps/meta/hotelQueue/requested'>
          <Queued />
        </Route>

      </Switch>
    </div>
  );
}
