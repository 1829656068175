import React, {useEffect, useRef, useState} from 'react';
import {
	Attachment,
	Button, FileInput,
	Input,
	NavDivider,
	Navigation,
	SelectInput,
	TOAST,
} from 'retro/index';
import {get, post} from 'App/Network/Axios';


export default function IRNUtilityController() {
	
	const EMAILS = useRef();
    const [loading, setLoading] = useState(false);
	const FILE = useRef();
    const [file,setFile] = useState(undefined);

	const irnUtility = () => {
		let emails = EMAILS.current.value;
        let urlLink=file
		if (!emails) {
			TOAST.error('Enter a valid email address');
			return;
		}
		if (!urlLink) {
			TOAST.error('Select a valid CSV File.');
			return;
		}
	
		setLoading(true);
		let data = {
			emails,
			urlLink
		};
		post(`/meta/irnUtility`, data, (e, r) => {
			if (e) {
				TOAST.handleError(e);
			} else {
				EMAILS.current.value = '';
				setFile(undefined);
				window.location.reload();
				TOAST.success('The IRN utility processing has been initiated. You will receive an email with the results shortly');
			}
			setLoading(false);
		});
	};
	return (
		<div>
			<Navigation backUrl={'back'} title="IRN Utility"/>
			<NavDivider/>
				<div className="mt4 pd4 border rounded-md">
					<div className="flex horizontally center-vertically mt2">
						<Input
							ref={EMAILS}
							type="text"
							label="Email"
							placeholder="Emails"
							className="flex-1 mr2"
							allowSpecialCharacters={true}
						/>
                        </div>
                        <div className="flex horizontally  mt2">
						<div className="flex mt4">
                        <FileInput updateFiles={(url)=>{
                           setFile(url)
                         }} 
						 label="Select File" 
						 extensions={[".csv"]}
						 ref={FILE}/>
                        <Attachment className='mt4' url={file}/>
                        </div>
					   </div>
					<Button
						onClick={irnUtility}
						loading={loading}
						className="btn-black btn-primary mr4"
						margin="pd2 mt4"
					>
						IRN Utility
					</Button>
				</div>
			
		</div>
	);
}
